export const menu = [
  {
    id: 1,
    menuName: "Save",
    url: "/save",
  },
  {
    id: 2,
    menuName: "Invest",
    url: "/invest",
  },

  {
    id: 3,
    menuName: "HYRE",
    url: "/hyre",
  },

  // {
  //   id: 3,
  //   menuName: "Financial Health Check",
  //   url: "https://fhc.getladda.com/",
  // },

  // {
  //   id: 3,
  //   menuName: "About Us",
  //   url: "/about-us",
  // },
  // {
  //   id: 3,
  //   menuName: "Pay Bills",
  //   url: "/pay-bills",
  // }
];
