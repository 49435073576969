export const investmentFaq = [
    {
      contentId: "01",
      faqQuestion: "Can I top up or add to my investment? ",
      faqAnswer:
        "  Yes, you can top up or add as you wish."
    },
    {
      contentId: "02",
      faqQuestion: "Can I automate my investments? ",
      faqAnswer:
        " Yes, you can automate your investment. You can simply set it and your investment runs according to the schedule.",
    },
    {
      contentId: "03",
      faqQuestion: "Can I pause my investment?",
      faqAnswer: "Yes, you can pause your investments",
    },
    {
      contentId: "04",
      faqQuestion: "Are the interest rates on my investment fixed?",
      faqAnswer:
        "No, it isn’t fixed. It fluctuates depending on what’s happening in the market.",
    },
    {
      contentId: "05",
      faqQuestion: "What is the minimum I can invest in Naira?",
      faqAnswer:
        "N500",
    }
  ];
  