import React, { useEffect, useState } from "react";
import styles from "./Accordion.module.scss";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { investmentFaq } from "../../util/investmentFaq";

const Accordion = () => {
  const [isActive, setIsActive] = useState(false);
  const [isInvestmentActive, setInvestmentActive] = useState(false);
  const [isBVNActive, setBVNActive] = useState(false);
  const [isMutualActive, setMutualActive] = useState(false);

  return (
    <>
      <div className={styles.accordion}>
        <>
          <div
            className={styles.accordionItem}
            onClick={() => setIsActive(!isActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionTitleName}>
                <div>Ladda</div>
              </div>
              <div className={styles.icons}>
                {isActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDivider}></div>
            {isActive && (
              <div className={styles.accordionContent}>
                <div>
                  <h6>What is Ladda? What does the name mean? </h6>
                  <p className={styles.mp}>
                    Ladda is a one-stop investment platform that caters to
                    different kinds of investors to help you grow your
                    investment step by step. Whether you have small money or big
                    money. Whether you want to save and invest in Naira or in
                    Dollars. Whether you want to save for a short time or for a
                    long time. Whether you want halaal options or not. We have
                    you covered.
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.accordionItem}
            onClick={() => setInvestmentActive(!isInvestmentActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionTitleName}>
                <div>Investment</div>
              </div>
              <div className={styles.icons}>
                {isInvestmentActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDivider}></div>
            {isInvestmentActive && (
              <div className={styles.accordionContent}>
                {investmentFaq.map((items) => (
                  <>
                    <div key={items.contentId}>
                      <h6>{items.faqQuestion}</h6>
                      <p className={styles.mp}>{items.faqAnswer}</p>
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>

          <div
            className={styles.accordionItem}
            onClick={() => setBVNActive(!isBVNActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionTitleName}>
                <div>BVN</div>
              </div>
              <div className={styles.icons}>
                {isBVNActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDivider}></div>
            {isBVNActive && (
              <div className={styles.accordionContent}>
                <div>
                  <h6>What do you need BVN for? </h6>
                  <p className={styles.mp}>
                    It is to comply with several regulatory bodies such as
                    Securities exchange commission (SEC).
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.accordionItem}
            onClick={() => setMutualActive(!isMutualActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionTitleName}>
                <div>Mutual Fund</div>
              </div>
              <div className={styles.icons}>
                {isMutualActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDivider}></div>
            {isMutualActive && (
              <div className={styles.accordionContent}>
                <div>
                  <h6>What is a mutual fund? </h6>
                  <p className={styles.mp}>
                    A mutual fund is when different people pools cash together
                    for the sole purpose of investing the cash in shares, bonds,
                    Treasury bills etc. What they invest the fund in describes
                    the mutual fund.
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
};
export default Accordion;
