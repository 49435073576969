import { lock } from "./images";
import { earning } from "./images";
import { cybersecurity } from "./images";

export const hyreAdvantage = [
  {
    id: 1,
    icon: <img src={lock} alt="Lock" />,
    header: "Carefully Managed",
    main: "Meticulously managed by our fund managers to ensure the safety of your investment. ",
    backgroundColor: "var(--orange-5, #FFF3E7)",
  },
  {
    id: 2,
    icon: <img src={earning} alt="Earning" />,
    header: "Flexible Lock-In Period",
    main: "Choose between 6 or 12 months lock-in to tailor your investment to your financial goals.",
    backgroundColor: "var(--green-5, #E4FFD7)",
  },
  {
    id: 3,
    icon: <img src={cybersecurity} alt="Cybersecurity" />,
    header: "Impressive Returns",
    main: "HYRE offers a 20% return per annum for 12 months and 16% per annum for 6 months. ",
    backgroundColor: "var(--red-5, #FFE6E6)",
  },
];
