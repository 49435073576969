import React from "react";
import { Grid } from "@mui/material";
import SEO from "../components/SEO";
import Accordion from "../components/Accordion/AccordionHyre";
import styles from "./Page.module.scss";
import Scroll from "react-scroll";
import { apple } from "../util/images";
import { googlePlayStore } from "../util/images";
import { hyre } from "../util/images";

import { hyreAdvantage } from "../util/hyreAdvantage";
import DownloadSection from "../components/DownloadSection/DownloadSection";

const Hyre = () => {
  const ScrollLink = Scroll.Link;
  return (
    <>
      <SEO
        title="Ladda HYRE Page"
        description="Ladda HYRE Page"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.containerBody}>
          <div className={styles.mt16}>
            <div className={styles.parentDiv16}>
              <div className={styles.leftDiv}>
                <div className={styles.textWidth2}>
                  <h1>
                    Protect your purchasing power with  High Yield Real Estate
                    (HYRE)
                  </h1>
                  <p className={styles.mt4}>
                    Worry less about the impact of inflation on your short-term
                    goals 
                  </p>

                  <div className={styles.mt16}>
                    <div className={styles.flex}>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ladda.ladda"
                        target="_blank"
                        rel="noopenner noreferrer"
                        title="Ladda on Google Playstore"
                      >
                        <div className={styles.downloadButton}>
                          <div className={styles.divflex}>
                            <img
                              src={googlePlayStore}
                              alt="Google Play Store"
                              width="auto"
                              height="auto"
                            />
                            <div className={styles.buttonText}>
                              <div>
                                <span>Get it on</span>
                                <br />
                                <div className={styles.mt2}>Google Play</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>

                      <a
                        href="https://apps.apple.com/ng/app/ladda/id1531879570"
                        target="_blank"
                        rel="noopenner noreferrer"
                        title="Ladda on Apple Playstore"
                      >
                        <div className={styles.downloadButton}>
                          <div className={styles.divflex}>
                            <img
                              src={apple}
                              alt="Apple Store"
                              width="auto"
                              height="auto"
                            />
                            <div className={styles.buttonText}>
                              <div>
                                <span>Get it on</span>
                                <br />
                                <div className={styles.mt2}>App Store</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.rightDiv}>
                <div className={styles.imageDiv}>
                  <img src={hyre} alt="Hyre" width="auto" height="auto" />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.parentDivHyre}>
            <div className={styles.parentDiv}>
              <div className={styles.center}>
                <h2>What sets HYRE apart</h2>
                <div className={styles.mt1}>
                  <p className={styles.p20}>
                    In a high inflation environment, traditional savings won’t
                    cut it anymore. That’s where Ladda’s HYRE comes in. 
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.mtbhyre}>
              <Grid container>
                {hyreAdvantage.map(
                  ({ id, icon, header, main, backgroundColor }) => (
                    <Grid item lg={4} md={6} xs={12} key={id}>
                      <div
                        className={styles.hyreBox}
                        style={{ backgroundColor: backgroundColor }}
                      >
                        <div>{icon}</div>
                        <div className={styles.header}>{header}</div>
                        <div className={styles.main}>{main}</div>
                      </div>
                    </Grid>
                  )
                )}
              </Grid>
            </div>
          </div>

          <div className={styles.paddingFaq}>
          <div className={styles.parentDiv16}>
            <div className={styles.leftDiv}>
              <div className={styles.textWidth2}>
                <h1>
                FAQs
                </h1>
              </div>
            </div>

            <div className={styles.rightDiv}>
              <Accordion />
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className={styles.parentDiv5}>
        <div className={styles.downloadMaxWidth}>
          <div id="download-section">
            <DownloadSection text1="Secure your future." text2="Invest now." />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hyre;
