import React from "react";
import SEO from "../components/SEO";
import styles from "./Page.module.scss";
import AppleOrange from "../assets/orange-apple.svg";
import GooglePlayStoreOrange from "../assets/orange-playstore.svg";
import DownloadSection from "../components/DownloadSection/DownloadSection";

const TermsAndConditions = () => {
  return (
    <>
      <SEO
        title="Ladda Terms and Conditions"
        description="Ladda Terms and Conditions"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.parentDiv10}>
          <div className={styles.center}>
            <h1 className={styles.white}>Terms &#38; Conditions</h1>
          </div>
        </div>

        <div className={styles.m90}>
          <div className={styles.parentDiv6}>
            <div className={styles.headerDiv}>Terms &#38; conditions</div>
            <div className={styles.privacyText}>
              <div className={styles.mtb1lr0}>
                These Terms of Service, together with any documents they
                expressly incorporate by reference (collectively, these{" "}
                <b>“Terms”</b>) govern your access and use of our website
                https://getladda.com and its subdomains (collectively,{" "}
                <b>“Website”</b>) or using or downloading our mobile application
                (the <b>“Ladda App”</b>) or any services of Ladda provided by
                Monafric Technology Limited (the Company). Accordingly, this is
                an agreement between Ladda, Monafric Technology Limited and its
                relevant affiliates and subsidiaries (collectively referred to
                herein as “the Company”, “Ladda”, “we”, “us”, or “our”) and You
                (together with the Company, the “Parties” and each a “Party”).
              </div>

              <div className={styles.mt1rem}>
                If you are using the Site in your capacity as an employee,
                owner, or otherwise as an agent of another person or entity, you
                hereby affirm that you are authorized to represent such person
                or entity and, in that capacity, you agree that the person or
                entity that you represent will be bound by these Terms of
                Service. You further represent and warrant that you are of legal
                age to form a binding contract, and that no legal reason exists
                that we should deny you access to the Site. If, at any time,
                these Terms of Service are no longer acceptable to you, you
                should cease using the Site immediately.
              </div>

              <div className={styles.mt1rem}>
                In addition to these Terms of Service, certain services offered
                for subscription or use through the Site may be subject to their
                own terms and conditions. Please be sure to review the
                applicable terms and conditions when before using such services.
                To the extent that there is a conflict between any terms of
                these Terms of Service and the terms and conditions specific to
                such services, the service-specific terms and conditions shall
                enjoy priority.
              </div>

              <div className={styles.mt1rem}>
                If you comply with these Terms of Service, we grant you the
                limited right to use the Site and the Services. The right to use
                the Site and the Services is a personal, restricted,
                non-exclusive, non-transferable, non-sublicensable, revocable,
                limited licence, and it is subject to the limitations and
                obligations in these Terms of Service. Nothing in these Terms of
                Service gives you any licence (other than as set out in this
                paragraph), right, title, or ownership of, in, or to the Site,
                any of the Services, the Copyrights or the Marks. The Site may
                suspend or terminate the Services to you, your Account or Wallet
                at its sole discretion, as required by applicable Laws or where
                we determine that you have violated, breached, or acted
                inconsistent with any of these Terms of Service.
              </div>

              <div className={styles.mt1rem}>
                By becoming a User of the Services, you agree to be contacted by
                us via communication modes including but not limited to email,
                mobile app notifications, SMS, MMS, and telephone calls.
              </div>

              <div className={styles.mt1rem}>
                THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION. YOU AGREE AND
                UNDERSTAND THAT IF WE CANNOT RESOLVE A DISPUTE THROUGH OUR USER
                OPERATIONS TEAM, ALL DISPUTES ARISING UNDER THIS USER AGREEMENT
                SHALL BE SETTLED IN FINAL BINDING ARBITRATION. YOU ALSO AGREE
                AND UNDERSTAND THAT ENTERING INTO THIS AGREEMENT CONSTITUTES A
                WAIVER OF YOUR RIGHT TO LITIGATION BEFORE THE REGULAR COURT AND
                PARTICIPATION IN A CLASS ACTION LAWSUIT.
              </div>

              <div className={styles.mt1rem}>
                <b>
                  Please carefully review these Terms before using the Services
                  or accessing any data here. Any use of the Services creates a
                  binding agreement to comply with these Terms. If you do not
                  agree to these terms without limitation or exclusions, you
                  must exit this interface immediately.
                </b>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.parentDiv6}>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>
              1. General use and conduct
            </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                <div>
                  <b>1.1 Eligibility </b>
                  By entering into this User Agreement, you affirm that you are
                  an individual, at least 18 years of age or older, have the
                  capacity to enter into this Agreement and agree to be legally
                  bound by the terms and conditions of this Agreement, including
                  the Cookie Policy and the Privacy Policy as incorporated
                  herein, as amended from time to time. In order to use the
                  Services, you may be required to provide certain identifying
                  information pursuant to our know-your-customer and anti-money
                  laundering compliance program (“Compliance Program”).
                </div>
              </div>

              <div className={styles.mt16}>
                <b>1.2 Modification </b>
                <br />
                1.2.1 We may change the terms of this User Agreement at any
                time. Any such changes will take effect when posted on the
                Website, or when you use the Services. If you have supplied us
                with an email address through the newsletter, we may also
                attempt to notify you by email of changes to these Terms of
                Service. <br />
                1.2.2 It is your responsibility to update your contact
                information and signing up to our newsletter, including but not
                limited to the email address provided to us (if any); failure to
                do so may result in you not receiving notice of any such changes
                to these Terms of Service.
                <br /> 1.2.3 Read the Terms of Service carefully on each
                occasion you use the Services. Your continued use of the
                Services shall signify your acceptance to be bound by the extant
                Terms of Service.
              </div>

              <div className={styles.mt16}>
                <b>1.3 Non Waiver </b>
                <br />
                Our failure to exercise or enforce any right or provision of
                these Terms of Service shall not constitute a waiver of such
                right or provision, and no waiver by us of a breach or default
                hereunder shall be deemed to be a waiver of any preceding or
                subsequent breach or default.
              </div>

              <div className={styles.mt16}>
                <b>1.4 Applicable Laws and Regulations </b>
                <br />
                Your relationship with us and use of any of the Services may be
                subject to the laws, regulations, and rules of governmental or
                regulatory authorities in your or our jurisdiction (“Applicable
                Law”). By entering into this Agreement, you agree to act in
                compliance with and be legally bound to any and all Applicable
                Law. You agree to abide by all such laws and regulations,
                including those relating to intellectual property, defamation,
                privacy, and the transmission or exportation of data. You also
                agree that you will not do or assist any third party to do, any
                of the following Prohibited uses:
                <ul className={styles.disc}>
                  <li>
                    interfere with another user's use or enjoyment of the Site;
                  </li>
                  <li>
                    access the Site through an unauthorized interface or in any
                    other unauthorized manner;
                  </li>
                  <li>
                    modify, adapt, sublicense, translate, sell, reverse
                    engineer, decompile or disassemble any portion of the Site,
                    or in any way make the Site available through any media to
                    any third party;
                  </li>
                  <li>
                    use any robot, spider, site search/retrieval application, or
                    other manual or automatic device or process to access,
                    search, download, retrieve, index or "data mine" any content
                    or data from the Site, or in any way reproduce or circumvent
                    the navigational structure or presentation of the content
                    available through the Site;
                  </li>
                  <li>
                    interfere with or disrupt the proper working or the security
                    of the Site, or any services or activities conducted on or
                    through the Site, including by "hacking" or defacing any
                    portion of the Site;
                  </li>
                  <li>
                    introduce any virus or other computer programme or
                    programming routine that may damage or interfere with the
                    operation of the Site, any content from the Site, any
                    services or activities conducted on or through the Site, or
                    any system;
                  </li>
                  <li>
                    unlawfully intercept any data or confidential or personal
                    information; or interfere with or disrupt any network
                    connected to the Site.
                  </li>
                  <li>
                    send junk e-mail, chain letters, duplicative, spam or
                    unsolicited messages, advertisements;
                  </li>
                  <li>
                    misrepresent (by statement or by omission) your identity,
                    credentials, affiliations or experience, or impersonate any
                    person or entity; stalk, threaten, or otherwise harass any
                    person or entity;
                  </li>
                  <li>
                    provide material support or resources to, or conceal or
                    disguise the nature, location, source, or ownership of
                    material or resources of, any organization designated as
                    terrorist organization pursuant to any national or
                    international laws.
                  </li>
                  <li>
                    use the Site or any Services in order to disguise the origin
                    or nature of illicit proceeds of, or to further, any breach
                    of applicable Laws;
                  </li>
                  <li>
                    use the Site or any Services to evade taxes under any Laws
                    in jurisdiction(s) applicable to you or the Site;
                  </li>
                  <li>
                    use the Site or any Services with anything other than funds
                    that have been legally obtained by you and that belong to
                    you;
                  </li>
                  <li>
                    use the Site or any Services to interfere with or subvert
                    our rights or obligations or the rights or obligations of
                    any other Site user or any other third party or cause legal
                    liability for us or other Site user;
                  </li>
                  <li>
                    exploit or take advantage of any technical glitch,
                    malfunction, failure, delay, default, or security breach on
                    the Site;
                  </li>
                  <li>
                    use the Site or any Services to engage in conduct that is
                    detrimental to us or to any other Site user or any other
                    third party;
                  </li>
                  <li>
                    use the Site or any Services to:
                    <ol type="i">
                      <li>
                        engage or attempt to engage in wash trading, spoofing,
                        fictitious trading or price manipulation;
                      </li>
                      <li>
                        enter orders with the intent to disrupt, or with
                        reckless disregard for the adverse impact on, the
                        orderly conduct of trading or the fair execution of
                        transactions; or
                      </li>
                      <li>
                        enter orders or quotes with the intent of creating the
                        false impression of market depth or market interest;
                      </li>
                    </ol>
                  </li>
                  <li>
                    falsify any account, Wallet, exchange or administration
                    details provided to us, impersonate another Person or
                    misrepresent your affiliation with a Person;
                  </li>
                  <li>
                    falsify or materially omit any information or provide
                    misleading or inaccurate information requested by us,
                    including at registration or during the course of
                    administering any Services to you;
                  </li>
                  <li>
                    where you are subject to prohibitions or restrictions as set
                    forth, access the Site or use any Services utilizing any
                    virtual private network, proxy service, or any other third
                    party service, network, or product with the effect of
                    disguising your IP address or location;
                  </li>
                  <li>
                    where you are subject to prohibitions or restrictions as set
                    forth, access the Site or use any Services utilizing any
                    virtual private network, proxy service, or any other third
                    party service, network, or product with the effect of
                    disguising your IP address or location;
                  </li>
                  <li>
                    violate, cause a violation of, or conspire or attempt to
                    violate these Terms of Service or applicable Laws.
                  </li>
                  <li>
                    If we determine that you have engaged in any Prohibited Use,
                    we may address such Prohibited Use through an appropriate
                    sanction, in our sole and absolute discretion. Such sanction
                    may include, but is not limited to, making a report to any
                    Government, law enforcement, or other authorities, without
                    providing any notice to you about any such report;
                    confiscation of any funds, property or proceeds in any
                    Wallet that you have on the Site; and, suspending or
                    terminating your access to any Services. We may, at our sole
                    and absolute discretion, seize and deliver your property to
                    any applicable Government, law enforcement, or other
                    authorities where circumstances warrant. In addition, should
                    your actions or inaction result in the imposition of
                    economic costs on us, you shall wholly indemnify us.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>2. Definitions</div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                <div>
                  2.1 <b>"Affilate" </b>means, in relation to either Party, a
                  direct or indirect subsidiary of the Party, a holding company
                  of the Party, and any other subsidiary of that holding
                  company;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.2 <b>"AML" </b>means anti-money laundering, including all
                  Laws applicable to the Parties prohibiting money laundering or
                  any acts or attempted acts to conceal or disguise the identity
                  or origin of; change the form of; or move, transfer, or
                  transport, illicit proceeds, property, funds, Fiat, or Digital
                  Tokens, including but not limited to the promotion of any
                  unlawful activity such as fraud, tax evasion, embezzlement,
                  insider trading, financial crime, bribery, cyber theft or
                  hack, narcotics trafficking, weapons proliferation, terrorism,
                  or Economic Sanctions violations, which may also require
                  internal controls to detect, prevent, report, and maintain
                  records of suspected money laundering or terrorist financing;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.3 <b>"Anti-Corruption" </b>means all Laws applicable to each
                  Party prohibiting corruption or bribery of Government
                  Officials, kickbacks, inducements, and other related forms of
                  commercial corruption or bribery;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.4 <b>"API" </b>means application programming interface, and
                  is not a Service, as defined below.
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.5 <b>"Controlling Person" </b> means any Person who owns
                  more than a 25 percent interest in any Person or affiliate;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.6 <b>"CRS" </b>means the common reporting standard or the
                  Standard for Automatic Exchange of Financial Account
                  Information;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.7 <b>"CTF" </b>means counter-terrorist financing;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.8 <b>"Wallet" </b>means a software application (or other
                  mechanism) that provides a means for holding, storing, and
                  transferring stock or funds;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.9 <b>"Economic Sanctions" </b>means financial sanctions,
                  trade embargoes, export or import controls, anti-boycott, and
                  restrictive trade measures enacted, administered, enforced, or
                  penalized by any Laws applicable to you or the Site;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.10 <b>"Government" </b>means any national, federal, state,
                  municipal, local, or foreign branch of government, including
                  any department, agency, subdivision, bureau, commission,
                  court, tribunal, arbitral body, or other governmental,
                  government appointed, or quasi-governmental authority or
                  component exercising executive, legislative, juridical,
                  regulatory, or administrative powers, authority, or functions
                  of or pertaining to a government instrumentality, including
                  any parasternal company, or state-owned (majority or greater)
                  or controlled business enterprise;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.11 <b>"Government Approval" </b>means any authorization,
                  license, permit, consent, approval, franchise, concession,
                  lease, ruling, certification, exemption, exception, or waiver
                  by or with any Government necessary to conduct the business of
                  either Party or the execution and delivery of the Services
                  under this Terms of Service;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.12 <b>"Government Official" </b>means an officer or employee
                  of any Government, a director, officer, or employee of any
                  instrumentality of any Government, a candidate for public
                  office, a political party or political party official, an
                  officer or employee of a public international organization,
                  and any Person who is acting in an official capacity for any
                  of the foregoing, even if such Person is acting in that
                  capacity temporarily and without compensation;{" "}
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.13 <b>"Laws" </b>means all laws, statutes, orders,
                  regulations, rules, treaties, and/or official obligations or
                  requirements enacted, promulgated, issued, ratified, enforced,
                  or administered by any Government that apply to you or the
                  Site;
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.14 <b>"Personal Information" </b>has the meaning set out in
                  Privacy Policy.
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.15 <b>"Service" </b>means one or more features provided or
                  operated by us via website or local application (mobile,
                  desktop, or otherwise).
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.16 <b>"Terms of Service" </b>means these terms and
                  conditions of service, as they may be changed, amended, or
                  updated from time to time, including the following Site
                  policies and pages: the Risk Disclosure Statement; the Privacy
                  Policy; the Anti-Spam Policy; the Law Enforcement Requests
                  Policy; and the Fees Schedule;{" "}
                </div>
              </div>

              <div className={styles.mt1rem}>
                <div>
                  2.17 <b>"User Account" </b>means an account for Services
                  associated with both (a) a Wallet and (b) a verified identity
                  approved in accordance with our Compliance Program. A Wallet
                  is not, by itself, a User Account.{" "}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>3. User Account</div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                <div>
                  <b>3.1 Account Creation </b>
                </div>
              </div>

              <div className={styles.mt1rem}>
                3.1.1 Creating your own User Account may require you to use
                multiple methods of authentication or verification, and
                depending on the specific Service provided, may require you to
                complete our Compliance/On-boarding Program. <br />
                3.1.2 Creating your own User Account may require you to use
                multiple methods of authentication or verification, and
                depending on the specific Service provided, may require you to
                complete our Compliance/On-boarding Program. <br />
                3.1.3 You agree that you will not permit access to your login
                credentials to any other party and will not hold us responsible
                for any actions taken by individuals unauthorized to access your
                User Account.
                <br /> 3.1.4 We may offer optional enhanced security features
                for your Account (including, for example, two-factor
                authentication). We encourage, but may not require, you to use
                any such enhanced security features. If you do enable enhanced
                security features, it is your responsibility to ensure the
                security of, and your continuous control over, any device or
                account that may be associated with the enhanced security
                features.
              </div>
              <div className={styles.mt1rem}>
                <div>
                  <b>3.2 Compliance/Onboarding Program </b>
                </div>
              </div>

              <div className={styles.mt1rem}>
                Your access to one or more of our Services may be contingent
                upon successful completion of on-boarding processes which
                include verification of your identity, source of funds, and
                additional information we may request from time-to-time. The
                information we request may include, without limitation,
                personally identifiable information such as name, address,
                telephone number, date of birth, taxpayer identification or
                social security number, official government-issued photo
                identification, and bank account information. In providing this
                information to us you represent that it is accurate and agree to
                update your User Account information promptly, but in no event
                later than 30 days following any change in your User Account
                information. Failure to provide this information or update it
                promptly may result in certain Services being unavailable to
                you.
              </div>

              <div className={styles.mt1rem}>
                <div>
                  <b>3.3 User Account Suspension or Termination</b>
                </div>
              </div>

              <div className={styles.mt1rem}>
                3.3.1 You may close your User Account at any time, at your sole
                discretion. Depending on the Services available to you in your
                User Account, you may be required to take certain actions in
                order to complete a pending transaction or providing additional
                information prior to closing such User Account. You are solely
                responsible for any fees already incurred or associated with the
                closing of your User Account, whether incurred directly by you
                from the Company, or incurred by the Company on your behalf with
                a third party in order to complete any such action. Closing your
                User Account may not result in the deletion of information we
                hold about you.
                <br />
                3.3.2 You agree and understand that we reserve the right, in our
                sole discretion, to immediately suspend, freeze, or terminate
                your User Account or any Wallet in the event that you are
                suspected of having violated any provision of this User
                Agreement, believed to be in violation of Applicable Law, or are
                believed to be involved in activities or conduct detrimental to
                us.
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>4. The Wallet </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                <div>
                  <b>4.1 Description</b>
                </div>
              </div>

              <div className={styles.mt1rem}>
                4.1.1 An electronic Wallet may be provided to you by the Company
                to serve as a digital representation of stock or funds held
                under your Account. You are solely responsible for storing,
                outside of the Services, a backup of any Wallet, private key or
                transaction information that you maintain in your Wallet or
                otherwise with the Services. If you do not maintain a backup of
                your Wallet data outside of the Services, you may lose access to
                it in the event that we discontinue or no longer offer some or
                all of the Services. We are not responsible for maintaining this
                data on your behalf. <br />
                4.1.2 You agree that you will not permit access to your login
                credentials to any other party and will not hold us responsible
                for any actions taken by individuals unauthorized to access your
                User Account. <br />
                4.1.3 We may offer optional enhanced security features for your
                Account (including, for example, two-factor authentication). We
                encourage, but may not require, you to use any such enhanced
                security features. If you do enable enhanced security features,
                it is your responsibility to ensure the security of, and your
                continuous control over, any device or account that may be
                associated with the enhanced security features.
              </div>
              <div className={styles.mt1rem}>
                <div>
                  <b>4.2 Compliance/Onboarding Program </b>
                </div>
              </div>

              <div className={styles.mt1rem}>
                Depending on the nature of a proposed transaction using your
                Wallet, we may where it is applicable, charge you for service
                fees. The service fees will be displayed prior to you incurring
                the fee. We may attempt to calculate such a fee for you. Our
                calculation may not be sufficient, or it may be excessive. You
                may select a greater or lesser fee. You are solely responsible
                for paying any such fee and we will neither advance nor fund
                such a fee on your behalf, nor be responsible for any excess or
                insufficient fee calculation.
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>5. Means of Access </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                Website is generally intended to be viewed by a conventional web
                browser with a screen resolution of 800 by 600 pixels or
                greater. The Services are is meant to be accessed via a mobile
                phone with an Android or iOS operating system. Although you may
                use other means to access the Services, be aware that the
                Services may not appear accurately through other access methods,
                and you use them only at your own risk. You should not access
                the Services through devices or services that are designed to
                provide high-speed, automated, repeated access, unless such
                devices are approved or made available by us. You understand
                that to receive electronic deliveries, you must have internet
                access, a valid e-mail address, the ability to download and have
                ongoing access to applications as we may specify and a printer
                or other device to download and print or save any information
                you may wish to retain.{" "}
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>
              6. No Broker or Fiduciary Relationship{" "}
            </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                You acknowledge that the Company is not your professional
                advisor and has no fiduciary relationship or obligation to you
                in connection with any transactions or other decisions or
                activities effected by you using the Services. Nothing on the
                Platform or from the Company shall be construed as making any
                recommendation or investment advice of any kind whatsoever. You
                are solely responsible for evaluating the merits and risks
                associated with the use of any Content provided through the
                Service before making any decisions based on such Content. You
                agree not to hold Company or any Third-Party Provider liable for
                any possible claim for damages arising from any decision you
                make based on the Content or other information made available to
                you through the Service or any Third-Party Provider websites.
                Past performance data should not be construed as indicative of
                future results.
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>
              7. Risk Disclosure Statement
            </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                7.1 The markets shift quickly in terms of price, liquidity,
                market depth, and trading dynamics. The Services are also
                subject to cybersecurity risk, including the risk of a
                cyberattack or breach. The risk of loss in using the Services
                may be substantial and losses may occur over a short period of
                time. In addition, price and liquidity are subject to
                significant fluctuations that may be unpredictable. You are
                solely responsible and liable for any and all trading and
                non-trading activity on the Site and for knowing the true status
                of your investment on the Site, even if presented incorrectly by
                the Site at any time. You acknowledge and agree:
                <ol type="i">
                  <li>
                    to be fully responsible and liable for your trading and
                    non-trading actions and inactions on the Site and all gains
                    and losses sustained from your use of the Site and any of
                    the Services;
                  </li>
                  <li>
                    to be fully responsible for safeguarding access to, and any
                    information provided through, the Site and any of the
                    Services, including, but not limited to Wallet, usernames,
                    passwords, and bank account details; and
                  </li>
                  <li>
                    that we do not have control of, or liability for, any
                    products or services that are purchased or sold by third
                    parties using the Site and any of the Services. You further
                    acknowledge and agree that we are not liable for any losses
                    or issues that may arise from third-party transactions,
                    including, but not limited to, legality (including any
                    consequences for illegal transactions that may be triggered
                    under these Terms of Service), quality, delivery, or
                    satisfaction with any products purchased. In the event that
                    you are not satisfied with any goods or services purchased
                    from, or sold to, a third party using the Services, you must
                    handle those issues directly with the third-party seller or
                    buyer, as applicable.{" "}
                  </li>
                </ol>
                7.2 In the course of processing and sending any withdrawals, or
                when processing and receiving deposits, we may be required to
                share your user information with other contractual third
                parties, including financial institutions, or as required under
                applicable Laws or demanded upon a lawful request by any
                Government. You hereby irrevocably grant full permission and
                authority for us to share this information with such contractual
                third parties, or as required under applicable Laws or demanded
                upon a lawful request by any Government, and release us from any
                liability, error, mistake, or negligence related thereto.
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>8. Indemnity</div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                You agree to defend, indemnify, and hold the Company, its
                affiliates, its officers, directors, employees, agents,
                licensors, and suppliers, harmless from and against any claims,
                actions or demands, liabilities and settlements including
                without limitation, reasonable legal and accounting fees,
                resulting from, or alleged to result from, your violation of
                these Agreement.
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>9. Force Majeure </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                9.1 Neither party shall be liable to the other for any failure
                to perform any obligation under any Agreement which is due to an
                event beyond the control of such party including but not limited
                to any Act of God, terrorism, war, political or religious
                insurgence, insurrection, riot, civil unrest, act of civil or
                military authority, disease/virus outbreak, uprising,
                earthquake, flood or any other natural or man-made eventuality
                outside of our control, which causes the termination of an
                agreement or contract entered into, nor which could have been
                reasonably foreseen. 9.2 Any Party affected by such event shall
                forthwith inform the other Party of the same and shall use all
                reasonable endeavours to comply with the terms and conditions of
                any Agreement contained herein.
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>
              10. Content and Links to Third Party Sites/Content{" "}
            </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                10.1 The information and Content available on or posted to
                Website may not be timely or complete, and should not be relied
                upon for making any investment or other decisions. Information,
                such as stock prices, is not real-time and the past performance
                results of a security available for purchase through Website is
                not an indication of future performance.
                <br />
                10.2 All Content on Platform is presented only as of the date
                published or indicated, and may be superseded by subsequent
                market events or for other reasons. In addition, you are
                responsible for setting the cache settings on your browser to
                ensure you are receiving the most recent data. <br />
                10.3 Content available through the Site may contain links to
                other Internet websites (including links provided by us or on
                behalf of our customers). We neither control nor endorse such
                Third Party Content, Websites, nor have we reviewed or approved
                any information or content that appears on such other websites.
                You acknowledge and agree that we shall not be held responsible
                for the legality, accuracy, timeliness, quality, safety or
                inappropriate nature of any content, advertising, products,
                services, or information located on or through any other
                websites, nor for any loss or damages caused or alleged to have
                been caused by the use of or reliance on any such content. We
                recommend that you take reasonable precautions with regard to
                any links that you choose to click through (including any
                information or content that you choose to download through those
                links (for example, scanning for viruses), as you do so at your
                own risk.
                <br />
                10.4 You agree and understand that we are not responsible or
                liable for any loss or damage of any sort incurred as a result
                of any such dealings and your use of Third Party Content is at
                your own risk.
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>
              11. Intellectual Property{" "}
            </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                11.1 Unless otherwise indicated by us, all intellectual property
                rights and any content provided in connection with our Services,
                are our property or our licensors’ or suppliers’ and are
                protected by applicable intellectual property laws. We do not
                give any implied license for the use of the contents of the
                Services. <br />
                11.2 You accept and acknowledge that the material and content
                contained through our Services is made available lawful use only
                and that you may only use such material and content for the
                purpose of using the Services as reasonably envisaged under this
                Agreement. <br />
                11.3 You accept and acknowledge that the material and content
                contained through our Services is made available lawful use only
                and that you may only use such material and content for the
                purpose of using the Services as reasonably envisaged under this
                Agreement. <br />
                11.4 You may not sell or modify materials derived or created
                from the Services or reproduce, display, publicly perform,
                distribute or otherwise use the materials in any way for any
                public or commercial purpose. Your use of the materials on any
                other website or on a file-sharing or similar service for any
                purpose is strictly prohibited. You may not copy any material or
                content derived or created from the Services without our
                express, written permission. 11.5 We reserve any rights not
                expressly granted herein to use the materials contained on or
                through our Services.{" "}
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>
              12. Accuracy of Information and Record Keeping
            </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                12.1 We will use reasonable efforts to verify the accuracy of
                any information displayed, supplied, passing through or
                originating from the Services, but such information may not
                always be accurate or current. Accordingly, you should
                independently verify all information before relying on it and
                take full responsibility for any decisions or actions taken
                based upon such information.
                <br />
                12.2 You agree and understand that we make no representation or
                warranty of any kind, express or implied, statutory or
                otherwise, regarding the contents of the Services, information
                and functions made accessible through the Services.
                <br />
                12.3 You agree and understand that we make no representation or
                warranty of any kind, express or implied, statutory or
                otherwise, regarding the contents of the Services, information
                and functions made accessible through the Services.
                <br />
                12.4 Additionally, we own any feedback, suggestions, ideas, or
                other information or materials regarding our Products or
                Services that you provide, whether by email, posting through the
                Services or otherwise (“Feedback”). You hereby assign to the
                Company all right, title and interest to Feedback together with
                all associated intellectual property rights. You will not be
                entitled to, and hereby waive any claim for, acknowledgment or
                compensation based on any Feedback or any modifications made
                based on any Feedback.
                <br />
                12.5 The Company may, from time-to-time, respond to requests
                from law enforcement, regulators and policymakers by producing
                certain information about or relating to your use of our
                Services.
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>
              13. Limitation of Liability
            </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                13.1 In no event will the Company be liable for (a) any
                indirect, special, consequential, punitive, or exemplary damages
                or (b) any damages whatsoever in excess of the amount of the
                transaction in issue or under dispute. The Company shall not be
                liable for any damages resulting from loss of revenues, profit,
                goodwill, use, economic prospect, business interruption, or
                other tangible or intangible losses, arising out of or in
                connection with this Site or Services whether such damages are
                based on warranty, contract, tort, statute, or any other legal
                theory.
                <br />
                13.2 All transactions and use of Services on this Site, monetary
                and otherwise, are non-refundable, non-exchangeable and
                non-reversible, save and except in the event of proven gross
                negligence on our part or its representatives, in which event
                the you agree that you shall only be entitled to a refund of the
                amount actually received by us with respect to the transaction
                done by the you.
                <br />
                13.3 At all times, we shall use our best endeavour to ensure the
                security of the Services. You acknowledge that we shall not be
                liable for any form of fraud or financial loss occasioned by a
                breach of security, loss of data arising from your negligence,
                omission or other conducts.
                <br />
                13.4 The Company accepts no liability for goods and services
                purchases in any transaction with a third party. We may
                intervene in disputes between users and merchants concerning
                payments but have no obligation to do so.{" "}
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>
              14. Dispute Resolution and Technical Support
            </div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                14.1 With the exception only of disputes related to the
                enforcement or validity of the Company’s intellectual property
                rights, all disputes, controversies or claims arising out of or
                relating to these Terms or the Services, will be resolved
                through confidential binding arbitration.
                <div className={styles.mt1rem}>
                  YOU ACKNOWLEDGE THAT YOU HAVE READ THIS PROVISION CAREFULLY,
                  AND UNDERSTAND THAT IT LIMITS YOUR RIGHTS IN THE EVENT OF A
                  DISPUTE BETWEEN YOU AND US. YOU UNDERSTAND THAT YOU HAVE THE
                  RIGHT TO REJECT THIS PROVISION AS SET OUT EARLIER.
                  <div className={styles.mt1rem}>
                    14.2 In this Arbitration Section “Claim” means any dispute,
                    claim, or controversy (whether based on contract, tort,
                    intentional tort, constitution, statute, ordinance, common
                    law, or equity, whether pre-existing, present, or future,
                    and whether seeking monetary, injunctive, declaratory, or
                    any other relief) arising from or relating to this Agreement
                    or the relationship between us and you (including claims
                    arising prior to or after the date of the Agreement, and
                    claims that are currently the subject of purported class
                    action litigation in which you are not a member of a
                    certified class), and includes claims that are brought as
                    counterclaims, cross claims, third party claims or
                    otherwise, as well as disputes about the validity or
                    enforceability of this Agreement or the validity or
                    enforceability of this Arbitration Section.
                    <br />
                    14.3 Any Claim shall be resolved, upon the election of
                    either us or you, by binding arbitration administered by the
                    Lagos Multidoor Courthouse in accordance to the Arbitration
                    and Conciliation Act, Laws of the Federation of Nigeria
                    2004. Any arbitration under this Agreement will take place
                    on an individual basis.
                    <br />
                    14.4 Claims will be arbitrated by a Sole Arbitrator mutually
                    appointed by parties. If parties are unable to agree within
                    10 (ten) business days, either party may apply to the Lagos
                    Multi-door Courthouse who shall appoint the Sole Arbitrator.
                    Arbitration will be held in English Language and the seat of
                    arbitration shall be Lagos, Lagos State, Nigeria.
                    <br />
                    14.5 You agree that any dispute arising out of or related to
                    these Terms or the Services is personal to you and that any
                    dispute will be resolved solely through individual
                    arbitration and will not be brought as a class arbitration,
                    class action or any other type of representative proceeding.
                    <br />
                    14.6 We will use commercially viable means to provide
                    email-based technical support services, but may be unable to
                    respond timeously, especially during times of high volume of
                    complaints.
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.m50b}>
              <div className={styles.subHeaderDiv}>
                15. General Due Diligence/Regulatory Compliance, Anti-Money
                Laundering and Counter-Terrorist Financing:
              </div>
              <div className={styles.privacyText}>
                <div className={styles.mt1rem}>
                  15.1 We are committed to providing safe, compliant, and
                  reputable Services to identify, detect, prevent and report on
                  money laundering, terrorist financing, and other improper
                  activities under applicable AML Laws, CTF Laws,
                  Anti-Corruption Laws, Securities Exchange Laws and Economic
                  Sanctions Laws. Accordingly, we insist on a comprehensive and
                  thorough user due diligence process implementation and ongoing
                  analysis and reporting. By agreeing to these Terms of Service,
                  you shall affirmatively certify the country in which you are
                  domiciled or whose laws and jurisdiction you are under and
                  must provide promptly all information requested and necessary
                  to satisfy due diligence requirements and obligations pursuant
                  to applicable Laws and our compliance policies or procedures.
                  Additionally, we may assess whether you will make, or intend
                  to make, a deposit, withdrawal, or transfer of funds to, from,
                  or through any Financial Institution to facilitate the
                  provision of the Services, and if applicable, you must provide
                  all information requested and necessary to satisfy due
                  diligence requirements and obligations pursuant to applicable
                  Laws and our compliance policies or procedures. You agree to
                  provide promptly documentation, information, or records
                  requested by us at any time, including, without limitation, a
                  self-certification permitting the determination of tax
                  residence and status under applicable Laws. Such information
                  may include, but is not limited to, self-certifications as to
                  Controlling Persons. We need to retain certain information,
                  documentation, and records on file pursuant to applicable Laws
                  and its contractual relationships, and we hereby expressly
                  reserve the right to keep such information, documentation, and
                  records. Additionally, we monitor and assess suspicious
                  transactions under applicable AML, Anti-Corruption, as well as
                  undertake mandatory reporting to national and international
                  regulators. These undertakings shall apply even when you
                  suspend or terminate your relationship with us. Our policies
                  apply to any and all other funds or property being exchanged
                  on or through the Site or by any of you, your Affiliates.
                  <br />
                  15.2 We reserve the right to refuse registration to, to bar
                  transactions from or to, or to suspend or terminate the
                  administration of Services, for or with, any user for any
                  reason (or for no reason) at any time subject to any
                  limitations imposed by applicable Laws. Without limiting the
                  generality of the foregoing, this includes, but is not limited
                  to, any transfer, transaction, business, or dealing with any
                  Person that fails to meet our user due diligence standards,
                  requests, or requirements. In lieu of refusing registration or
                  to bar transactions from or to, or to suspend or terminate the
                  administration of Services, we may perform enhanced due
                  diligence procedures. At all times, you may be subject to
                  enhanced due diligence procedures in your use of the Site and
                  any Service. If you decline to provide requested due diligence
                  information or otherwise do not reply timely or substantively
                  with the documentation or data requested, the Site has the
                  absolute discretion to suspend or terminate Services to you
                  immediately.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.m50b}>
            <div className={styles.subHeaderDiv}>16. Miscellaneous</div>
            <div className={styles.privacyText}>
              <div className={styles.mt1rem}>
                <b>General: </b>
                Cumulative Rights: Except as otherwise provided herein, no
                rights or remedies of the Company in these Terms of Service are
                exclusive, but are cumulative of all other rights and remedies.
              </div>
              <div className={styles.mt1rem}>
                <b>Assignments: </b>
                The rights and obligation of the Company under these Terms of
                Service may be assigned or delegated, in whole or in part, by
                the Company to an affiliate or a third party in connection with
                a merger, acquisition, reorganization, or sale of assets. You
                may not assign or delegate these Terms of Service, in whole or
                in part, and any attempt to do so will be null and void.
              </div>
              <div className={styles.mt1rem}>
                <b>Severability of Terms: </b>
                If any provision of these Terms of Service is found by a court
                of competent jurisdiction to be invalid, illegal or otherwise
                unenforceable, such provision shall be deemed deleted from the
                Terms of Service and replaced with a provision that
                approximates, as nearly as possible, the intent of the offending
                provision while remaining valid, legal and enforceable. All
                other provisions of these Terms of Service shall remain in full
                force and effect.
              </div>
            </div>
          </div>
        </div>

        <div id="download-section">
        <DownloadSection
          text1="Make savings a priority today."
          text2=" Save Now."
        />
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
