//home
export const logo =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366346/Assets/logo.svg";
export const facebook =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366346/Assets/facebook-frame.svg";
export const instagram =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366347/Assets/instagram-frame.svg";
export const linkedin =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366347/Assets/linkedin-frame.svg";
export const twitter =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366347/Assets/twitter-frame.svg";

export const homeImage =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388649/Assets/home2.webp";
export const chart =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388644/Assets/chart2.webp";
export const smartSave =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388646/Assets/smart-saving2.webp";
export const creditCard =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388645/Assets/credit-card2.webp";
export const apple =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366344/Assets/apple.svg";
export const arrow =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676370909/Assets/arrow.svg";
export const googlePlayStore =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366345/Assets/playstore.svg";
export const applePlayStoreOrange =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366344/Assets/orange-apple.svg";
export const googlePlayStoreOrange =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366344/Assets/orange-playstore.svg";
export const padlock =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388649/Assets/padlock2.webp";
export const avatar =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366346/Assets/avatar.svg";
export const personalised =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366336/Assets/personalised.svg";
export const globalAssets =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366336/Assets/global-assets.svg";
export const automatedAdvisory =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676366339/Assets/automated-advisory.svg";
export const securityImage =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1692057173/Assets/security.webp";
export const hyreIndex =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1706621195/Assets/index/hyre.webp";

//save
export const home =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676369338/Assets/save/home.svg";
export const cooperation =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676369337/Assets/save/cooperation.svg";
export const redCarpet =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676369336/Assets/save/red-carpet.svg";
export const healthCare =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676369338/Assets/save/healthcare.svg";
export const sportCar =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676369337/Assets/save/sport-car.svg";
export const kids =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676369336/Assets/save/boy.svg";
export const travel =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676369338/Assets/save/travel.svg";
export const bag =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388863/Assets/save/bag.svg";
export const more =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676369333/Assets/save/more.svg";
export const goal =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676369335/Assets/save/goal.svg";
export const savings =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388867/Assets/save/save1_.webp";
export const multiplySavings =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388868/Assets/save/save2_.webp";

//invest
export const investStressFree =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388768/Assets/invest/invest1_.webp";
export const growWealth =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388771/Assets/invest/invest2_.webp";
export const retirement =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676368435/Assets/invest/retirement.svg";
export const capital =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676368428/Assets/invest/capital.svg";
export const education =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676368432/Assets/invest/education.svg";
export const others =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676368417/Assets/invest/others.svg";
export const phone =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676368448/Assets/invest/phone.svg";
export const phone2 =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676380672/Assets/invest/phone_new.svg";
export const steps =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676368421/Assets/invest/steps.svg";
export const shareGoals =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676388765/Assets/invest/share-goals2.webp";
export const clock =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676389974/Assets/invest/clock2.webp";
export const portfolio =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676368425/Assets/invest/portfolio.svg";
export const call =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676368428/Assets/invest/call.svg";
export const report =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676368422/Assets/invest/report.svg";
export const feeChart =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1692104709/Assets/invest/chart.svg";

//hyre

export const hyre =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1701854042/Assets/hyre/hyre.webp";
export const lock =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1701855042/Assets/hyre/lock.svg";
export const earning =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1701855162/Assets/hyre/earning.svg";
export const cybersecurity =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1701855052/Assets/hyre/cyber-security.svg";

//not-found
export const notFound =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676381848/Assets/not-found/not-found.svg";

//about-us
export const image2 =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676390030/Assets/about-us/image2_.webp";
export const image3 =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1676390030/Assets/about-us/image3_.webp";
