import React, { useEffect, useState } from "react";
import styles from "./Accordion.module.scss";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { investmentFaq } from "../../util/investmentFaq";

const Accordion = () => {
  const [isActive, setIsActive] = useState(false);
  const [isCapitalActive, setCapitalActive] = useState(false);
  const [isBenefitActive, setBenefitActive] = useState(false);
  const [isInvestmentActive, setInvestmentActive] = useState(false);
  const [isStepsActive, setStepsActive] = useState(false);

  return (
    <>
      <div className={styles.accordion}>
        <>
          <div
            className={styles.accordionItem}
            onClick={() => setIsActive(!isActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionHyreTitleName}>
                <div>Can I take out money before the tenure is due?</div>
              </div>
              <div className={styles.icons}>
                {isActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDividerHyre}></div>
            {isActive && (
              <div className={styles.accordionContent}>
                <div>
                  <p className={styles.mpHyre}>
                    This opportunity has a minimum 180 days (6 months) lock-in
                    period, after which investors can withdraw their investments
                    without penalties. If you have immediate or near-term
                    obligations in less than 6 months, this opportunity is not
                    suited for you. If you do not have an emergency fund, this
                    opportunity is not best suited for you as you might need to
                    make urgent withdrawals. 
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.accordionItem}
            onClick={() => setCapitalActive(!isCapitalActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionHyreTitleName}>
                <div>Is my capital safe?</div>
              </div>
              <div className={styles.icons}>
                {isCapitalActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDividerHyre}></div>
            {isCapitalActive && (
              <div className={styles.accordionContent}>
                <div>
                  <p className={styles.mpHyre}>
                    Real estate is a moderate risk investment. What this means
                    is that there is a moderate chance that the return you
                    expect might not be realised. However, we take risk
                    mitigation seriously and this has worked for our prior real
                    estate investments where investors got all of their returns.
                    But please know that past results cannot guarantee future
                    success.{" "}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.accordionItem}
            onClick={() => setBenefitActive(!isBenefitActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionHyreTitleName}>
                <div>What are the benefits?</div>
              </div>
              <div className={styles.icons}>
                {isBenefitActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDividerHyre}></div>
            {isBenefitActive && (
              <div className={styles.accordionContent}>
                <div>
                  <p className={styles.mpHyre}>
                    The main benefit is that it offers diversification for your
                    portfolio. This means that you can earn more returns in
                    Naira than on normal savings without taking on extreme
                    levels of risk. It is also helpful if you need to save
                    towards a short-term goal.
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.accordionItem}
            onClick={() => setInvestmentActive(!isInvestmentActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionHyreTitleName}>
                <div>
                  Is this a new investment portfolio for the team or is there
                  experience in this sector?
                </div>
              </div>
              <div className={styles.icons}>
                {isInvestmentActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDividerHyre}></div>
            {isInvestmentActive && (
              <div className={styles.accordionContent}>
                <div>
                  <p className={styles.mpHyre}>
                    We started operating in the sector in Q2 2023. We are driven
                    by the resounding success since then to expand on this
                    opportunity. Learnings from our prior experience also makes
                    us better equipped to manage the risks involved.{" "}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.accordionItem}
            onClick={() => setStepsActive(!isStepsActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionHyreTitleName}>
                <div>What are the steps to address a default if it occurs?</div>
              </div>
              <div className={styles.icons}>
                {isStepsActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDividerHyre}></div>
            {isStepsActive && (
              <div className={styles.accordionContent}>
                <div>
                  <p className={styles.mpHyre}>
                    We conduct proper due diligence on investment opportunities
                    before recommending to our members. We have also taken
                    appropriate safeguards by ensuring that the real estate fund
                    has a low risk component that will make investors whole even
                    if the real estate assets do not perform according to
                    expectation. In the extreme case, real estate assets (we
                    have these in prime areas with high demand) can be
                    liquidated to make obligations to investors.
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
};
export default Accordion;
