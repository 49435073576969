import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const ScrollToTop = (props: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return <>{props.children}</>;
}

export default ScrollToTop
