import React from "react";
import SEO from "../components/SEO";
import { Grid } from "@mui/material";
import styles from "./Page.module.scss";
import { apple } from "../util/images";
import { applePlayStoreOrange } from "../util/images";
import { googlePlayStore } from "../util/images";
import { googlePlayStoreOrange } from "../util/images";
import { home } from "../util/images";
import { cooperation } from "../util/images";
import { redCarpet } from "../util/images";
import { healthCare } from "../util/images";
import { sportCar } from "../util/images";
import { kids } from "../util/images";
import { travel } from "../util/images";
import { bag } from "../util/images";
import { more } from "../util/images";
import { goal } from "../util/images";
import { savings } from "../util/images";
import { multiplySavings } from "../util/images";
import DownloadSection from "../components/DownloadSection/DownloadSection";

const Save = () => {
  return (
    <>
      <SEO
        title="Ladda Savings Page"
        description="Ladda Savings Page"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.containerBody}>
          <div className={styles.parentDiv2}>
            <div className={styles.leftDiv}>
              <h1 className={styles.mtsave}>
                Spend less. <br /> Save more.
              </h1>
              <p className={styles.mt4}>
                <div className={styles.savepwidth}>
                  Don’t just save towards your financial goals, earn better
                  interest on your savings too.
                </div>
              </p>
              <div className={styles.mt1rem}>
                <div className={styles.flex}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ladda.ladda"
                    target="_blank"
                    rel="noopenner noreferrer"
                    title="Ladda on Google Playstore"
                  >
                    <div className={styles.downloadButton}>
                      <div className={styles.divflex}>
                        <img
                          src={googlePlayStore}
                          alt="Google Play Store"
                          width="auto"
                          height="auto"
                        />
                        <div className={styles.buttonText}>
                          <div>
                            <span>Get it on</span>
                            <br />
                            <div className={styles.mt2}>Google Play</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>

                  <a
                    href="https://apps.apple.com/ng/app/ladda/id1531879570"
                    target="_blank"
                    rel="noopenner noreferrer"
                    title="Ladda on Apple Playstore"
                  >
                    <div className={styles.downloadButton}>
                      <div className={styles.divflex}>
                        <img
                          src={apple}
                          alt="Apple Store"
                          width="auto"
                          height="auto"
                        />
                        <div className={styles.buttonText}>
                          <div>
                            <span>Get it on</span>
                            <br />
                            <div className={styles.mt2}>App Store</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.rightDiv}>
              <div className={styles.imageDiv}>
                <img
                  src={savings}
                  alt="Savings"
                  className={styles.image}
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
          </div>

          <div className={styles.parentDivHome}>
            <div className={styles.leftDiv2}>
              <div className={styles.imageDiv}>
                
                <img
                  src={multiplySavings}
                  alt="Multiply Savings"
                  width="auto"
                  height="auto"
                  className={styles.mtMobile}
                />
              </div>
            </div>
            <div className={styles.rightDiv2}>
              <div className={styles.iHeaderWidth}>
                <h2>
                  Create multiple saving plans to make your life goals happen!
                </h2>
              </div>
              <p className={styles.mt1}>
                Whether it’s saving for a new home, kids’ education or
                travelling the world; focus on what matters and save your way to
                make it a reality
              </p>
            </div>
          </div>

          <div className={styles.m40}>
            <div className={styles.parentDiv3}>
              <div className={styles.m40}>
                <div className={styles.parentDiv4}>
                  <Grid container spacing={2}>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={home}
                            alt="Home"
                            width="auto"
                            height="auto"
                          />
                          <h4>House/Rent</h4>
                          <p className={styles.mt1}>
                            Save up to buy your dream house or to make rent
                            payment easier
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={travel}
                            alt="Vacation"
                            width="auto"
                            height="auto"
                          />
                          <h4>Vacations/Trips</h4>
                          <p className={styles.mt1}>
                            Set money aside take your dream vocations then and
                            again
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={healthCare}
                            alt="Health"
                            width="auto"
                            height="auto"
                          />
                          <h4>Health</h4>
                          <p className={styles.mt1}>
                            Keep a budget for personal and family health
                            emergencies.
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img src={cooperation} alt="Cooperation" />
                          <h4>Cooperation</h4>
                          <p className={styles.mt1}>
                            Save to finance your business or save from your
                            business
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.parentDiv4}>
                  <Grid container spacing={2}>
                    <Grid item lg={1} md={1} xs={12}></Grid>
                    <Grid item lg={3} md={4} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={sportCar}
                            alt="Car"
                            width="auto"
                            height="auto"
                          />
                          <div className={styles.mt3}>
                            <h4>Car</h4>
                            <p className={styles.mt1}>
                              Want that sleek car? Save towards buying it
                            </p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={redCarpet}
                            alt="Ceremony"
                            width="auto"
                            height="auto"
                          />
                          <h4>Ceremony</h4>
                          <p className={styles.mt1}>
                            Create a saving plan for weddings, housewarmings,
                            graduations and more
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={kids}
                            alt="Kids"
                            width="auto"
                            height="auto"
                          />
                          <h4>Kids</h4>
                          <p className={styles.mt1}>
                            Secure your kids’ future. Save to cover their
                            education
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={1} md={0} xs={12}></Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.mtb80}>
            <div className={styles.center}>
              <h2>Why save with Ladda?</h2>
            </div>
            <div className={styles.parentPadding}>
              <Grid container spacing={4}>
                <Grid item lg={4} md={4} xs={12}>
                  <div className={styles.saveBox1}>
                    <div>
                      <img
                        src={goal}
                        alt="Goal Oriented"
                        width="auto"
                        height="auto"
                      />
                    </div>
                    <div className={styles.savingGridWidth}>
                      <h4>Goal Oriented</h4>
                      <div className={styles.mt1}>
                        <p>Reach multiple goals by saving towards them</p>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <div className={styles.saveBox2}>
                    <div>
                      <img
                        src={bag}
                        alt="Flexible"
                        width="auto"
                        height="auto"
                      />
                    </div>
                    <div className={styles.savingGridWidth}>
                      <h4>Flexible</h4>
                      <div className={styles.mt1}>
                        <p>Manage your money any time, any day</p>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <div className={styles.saveBox3}>
                    <img
                      src={more}
                      alt="Earn more"
                      width="auto"
                      height="auto"
                    />
                    <div className={styles.savingGridWidth}>
                      <h4>Earn more</h4>
                      <div className={styles.mt1}>
                        <p>Get better interest than your bank when you save</p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.parentDiv5}>
        <div className={styles.downloadMaxWidth}>
          <div id="download-section">
            <DownloadSection
              text1="Now is the time to start saving."
              text2="Get Started."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Save;
