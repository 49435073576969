
import { personalised } from './images'
import { automatedAdvisory } from './images'
import { globalAssets } from './images'

export const wealth = [
    {
      id: 1,
      icon: <img src={personalised} alt="Personalised Investment" />,
      header: "Personalised Investing",
      main: "Earn great returns by investing in portfolios designed by experts and personalised for you."
    },
    {
      id: 2,
      icon: <img src={automatedAdvisory} alt="Automated Advisory" />,
      header: "Automated Advisory",
      main: "We keep an eye on the market for you and manage your portfolio for high returns."
    },
    {
      id: 3,
      icon: <img src={globalAssets} alt="Global Assets" />,
      header: "Best Global Assets",
      main: "Create a portfolio of the best global assets in one app to grow your wealth."
    },
  ]