import React from "react";
import SEO from "../components/SEO";
import styles from "./Page.module.scss";
import { notFound } from "../util/images";
import DownloadSection from "../components/DownloadSection/DownloadSection";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <>
      <SEO
        title="Ladda Not Found Page"
        description="Ladda Not Found Page"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.parentPadding3}>
          <div className={styles.imageDiv}>
            <img src={notFound} alt="Page Not Found" width="auto" height="auto"/>
          </div>
          <div className={styles.mt7}>
            <div className={styles.notFoundText}>
              The page you are looking for could not be found.
            </div>
          </div>
          <div className={styles.divflexColumn}>
            <div className={styles.notFoundButton} onClick={goHome}>Go to Homepage</div>
          </div>
        </div>

        <div id="download-section">
          <DownloadSection
            text1="Now is the time to start saving."
            text2="Get Started."
          />
        </div>
      </div>
    </>
  );
};

export default NotFound;
