import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import { logo } from "../../util/images";
import { facebook } from "../../util/images";
import { instagram } from "../../util/images";
import { linkedin } from "../../util/images";
import { twitter } from "../../util/images";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FooterMUI = () => {
  const [showGoTop, setShowGoTop] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<string | number>("");

  const navigate = useNavigate();

  const handleVisibleButton = () => {
    setShowGoTop(window.pageYOffset > 50);
  };

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  }, []);

  const handleSave = () => {
    navigate("save");
  };

  const handleInvest = () => {
    navigate("invest");
  };

  const handleFHC = () => {
    const url = "https://fhc.getladda.com/";
    window.open(url, "_blank");
  };

  const handleHYRE = () => {
    navigate("hyre");
  };

  const handleBills = () => {
    navigate("pay-bills");
  };

  const handleAboutUs = () => {
    navigate("about-us");
  };

  const handlePrivacy = () => {
    navigate("privacy-policy");
  };

  const handleLegal = () => {
    navigate("legal");
  };

  const handleTerms = () => {
    navigate("terms-and-conditions");
  };

  const handleFAQs = () => {
    navigate("faqs");
  };
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.footerHeader}>
          <div className={styles.logo}>
            <img src={logo} alt="Ladda Logo" width="auto" height="auto" />
          </div>
          <div className={styles.socialIcons}>
            <a
              href="https://www.facebook.com/getladda/"
              target="_blank"
              rel="noopenner noreferrer"
              title="Ladda on Facebook"
            >
              <img
                src={facebook}
                alt="Facebook Image"
                width="auto"
                height="auto"
              />
            </a>
            <a
              href="https://www.instagram.com/ladda.ng"
              target="_blank"
              rel="noopenner noreferrer"
              title="Ladda on Instagram"
            >
              <img
                src={instagram}
                alt="Instagram Image"
                width="auto"
                height="auto"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/ladda-ng"
              target="_blank"
              rel="noopenner noreferrer"
              title="Ladda on LinkedIn"
            >
              <img
                src={linkedin}
                alt="LinkeIn Image"
                width="auto"
                height="auto"
              />
            </a>
            <a
              href="https://twitter.com/getladda"
              target="_blank"
              rel="noopenner noreferrer"
              title="Ladda on Twitter"
            >
              <img
                src={twitter}
                alt="Twitter Image"
                width="auto"
                height="auto"
              />
            </a>
          </div>
        </div>
        <div className={styles.hr}></div>
        <div className={styles.m40}>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} xs={12}>
              <div className={styles.footerSubHead}>Company</div>
              <div className={styles.footerOptions}>
                <div className={styles.pointer} onClick={handleAboutUs}>
                  About Us
                </div>
                <div className={styles.pointer} onClick={handlePrivacy}>
                  Privacy Policy
                </div>
                {/* <div onClick={handleLegal}>Legal</div> */}
                <div className={styles.pointer} onClick={handleTerms}>
                  Terms &amp; Conditions
                </div>
                <div className={styles.pointer} onClick={handleFAQs}>
                  FAQs
                </div>
              </div>
            </Grid>
            <Grid item lg={3} md={3} xs={12}>
              <div className={styles.footerSubHead}>Services</div>
              <div className={styles.footerOptions}>
                <div onClick={handleSave} className={styles.pointer}>
                  Save
                </div>
                <div onClick={handleInvest} className={styles.pointer}>
                  Invest
                </div>
                <div onClick={handleHYRE} className={styles.pointer}>
                  HYRE
                </div>
                {/* <div onClick={handleFHC} className={styles.pointer}>
                  FHC
                </div> */}
                {/* <div onClick={handleBills}>Pay Bills</div> */}
              </div>
            </Grid>
            <Grid item lg={3} md={3} xs={10}>
              <div className={styles.footerSubHead}>Contact</div>
              <div className={styles.footerOptions}>
                <div>+234 906 631 8820</div>
                <div>hello@getladda.com</div>
                <div>
                Plot 2B, Abayomi Shonuga Crescent, Lekki Phase 1, Lagos
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.hr}></div>
        <div className={styles.footerBase}>
          <div>&#169;2023 Ladda &#45; All Rights Reserved.</div>
          <div onClick={handleScrollUp} className={styles.pointer}>
            Back to top
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterMUI;
