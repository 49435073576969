export const security = [
  {
    id: 1,
    header: "Data Privacy",
    main: "We prioritize protecting your data. Our secure servers ensure that no third-party is granted access to your personal information. We collect and use your personal data to constantly improve your user experience",
  },
  {
    id: 2,
    header: "Bank Level Security",
    main: "All application traffic is encrypted and protected by using 256-bit AES bank level encryption. This provides security between your devices and our servers ensuring your personal and transaction details are always kept private. ",
  }
];
