import React, { ReactNode } from "react";
import styles from "../../views/Page.module.scss";
import { applePlayStoreOrange } from "../../util/images";
import { googlePlayStoreOrange } from "../../util/images";

type DownloadText = {
  text1: string | ReactNode;
  text2: string;
};

const DownloadSection = ({ text1, text2 }: DownloadText) => {
  return (
    <div className={styles.mdownload}>
      <div className={styles.parentDiv5}>
        <div className={styles.parentDivDownload}>
          <div className={styles.leftDiv2}>
            <div className={styles.widthR}>
              <h2 className={styles.iHeaderDownload}>
                <span className={styles.white}>
                 <span> {text1}</span>
                  <br />
                  {text2}
                </span>
              </h2>
            </div>
          </div>

          <div className={styles.rightDiv6}>
            <div className={styles.flex}>
              <a
                href="https://play.google.com/store/apps/details?id=com.ladda.ladda"
                target="_blank"
                rel="noopenner noreferrer"
                title="Ladda on Google Playstore"
              >
                <div className={styles.downloadButton2}>
                  <div className={styles.divflex}>
                    <img src={googlePlayStoreOrange} alt="Google Play Store" />

                    <div className={styles.buttonText}>
                      <div>
                        <span>Get it on</span>
                        <br />
                        <div className={styles.mt2}>Google Play</div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>

              <a
                href="https://apps.apple.com/ng/app/ladda/id1531879570"
                target="_blank"
                rel="noopenner noreferrer"
                title="Ladda on Apple Playstore"
              >
                <div className={styles.downloadButton2}>
                  <div className={styles.divflex}>
                    <img src={applePlayStoreOrange} alt="Apple Store" />
                    <div className={styles.buttonText}>
                      <div>
                        <span>Get it on</span>
                        <br />
                        <div className={styles.mt2}>App Store</div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection;
