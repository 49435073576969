import React, { useRef } from "react";
import SEO from "../components/SEO";
import styles from "./Page.module.scss";
import { image2 } from "../util/images";
import { image3 } from "../util/images";
import { Grid } from "@material-ui/core";
import DownloadSection from "../components/DownloadSection/DownloadSection";

const AboutUs = () => {

  return (
    <>
      <SEO
        title="Ladda About Us"
        description="Ladda About Us"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.containerBody}>
          <div className={styles.parentDiv2}>
            <div className={styles.backgroundImage}>
              <div className={styles.imageText}>
                <h1 className={styles.center}>
                  Transforming lives by making investing easier
                </h1>
              </div>
            </div>
          </div>

          <div className={styles.parentDiv2}>
            <div className={styles.leftDiv}>
              <div className={styles.imageDiv3}>
                <img
                  src={image2}
                  alt="Stress Free Investment"
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
            <div className={styles.rightDiv}>
              <div>
                <h2>Our Story - You’ve been a part of it!</h2>
                <p>
                  We are passionate about your personal finance and streamlining
                  investment for you, so we created access to a myriad of
                  world-class investment vehicles all on one platform. We had
                  fun doing it!
                </p>
                <p>
                  Ease and convenience are wired into our design as we want you
                  to be able to invest on your terms, play the long-term game
                  with peace of mind, and create lasting wealth with little
                  stress. We believe payments, saving, and investing should be
                  hassle-free, automated and easily accessible just like
                  everything else in today’s world.
                </p>
                <p>
                  That’s why we built <b>Ladda</b> to help you reach your
                  financial goals faster through simplified saving, payments,
                  robo-advisory and mutual fund investments. Best part? You can
                  invest in local or foreign investments for as little as{" "}
                  <b>₦500</b> and get returns
                </p>
              </div>
            </div>
          </div>

          <div className={styles.parentPadding1}>
            <div className={styles.flex}>
              <div className={styles.aboutDivWidth1}>
                <div className={styles.aboutDiv}>
                  <h2 className={styles.mt6}>Our Mission</h2>
                  <p className={styles.mt3}>
                    Our mission is to provide world-class tools to aid your
                    financial independence
                  </p>
                </div>
              </div>
              <div className={styles.mtLines}>
                <div>
                  <img
                    src={image3}
                    alt="Our Mission"
                    width="auto"
                    height="auto"
                  />
                </div>
              </div>
            </div>

            <div className={styles.mt16}>
              <div className={styles.flex}>
                <div className={styles.mtLines}>
                  <div>
                    <img
                      src={image3}
                      alt="Our Vision"
                      width="auto"
                      height="auto"
                    />
                  </div>
                </div>

                <div className={styles.aboutDivWidth2}>
                  <div className={styles.aboutDiv}>
                    <h2 className={styles.mt6}>Our Vision</h2>
                    <p className={styles.mt3}>
                      To become your financial partner for everyday money
                      decisions and to propel you to your money goals
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="download-section">
            <DownloadSection
              text1="Make savings a priority today."
              text2="Save Now."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
