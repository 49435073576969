import React from "react";
import { Grid } from "@mui/material";
import SEO from "../components/SEO";
import styles from "./Page.module.scss";
import { apple } from "../util/images";
import { googlePlayStore } from "../util/images";
import { investStressFree } from "../util/images";
import { growWealth } from "../util/images";
import { retirement } from "../util/images";
import { capital } from "../util/images";
import { education } from "../util/images";
import { others } from "../util/images";
import { phone } from "../util/images";
import { phone2 } from "../util/images";
import { steps } from "../util/images";
import { shareGoals } from "../util/images";
import { clock } from "../util/images";
import { portfolio } from "../util/images";
import { call } from "../util/images";
import { report } from "../util/images";
import DownloadSection from "../components/DownloadSection/DownloadSection";

const Invest = () => {
  return (
    <>
      <SEO
        title="Ladda Investment Page"
        description="Ladda Investment Page"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.scrollContainer}>
          <div className={styles.scrollText}>This feature is coming soon</div>
        </div>
        <div className={styles.containerBody}>
          <div className={styles.mtinvest}>
            <div className={styles.parentDiv2}>
              <div className={styles.leftDiv3}>
                <div className={styles.textWidth}>
                  <h1>Stress-free investing tailored to your needs</h1>
                  <p className={styles.mt4}>
                    You don’t need to become a financial expert to invest in
                    your future. With our done-for-you investment vehicles, you
                    simply tell us your goals and sit back while your money
                    grows.
                  </p>
                </div>
              </div>

              <div className={styles.rightDiv}>
                <div className={styles.imageDiv}>
                  <img
                    src={investStressFree}
                    alt="Stress Free Investment"
                    width="auto"
                    height="auto"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className={styles.mtb2lr0}> */}
            <div className={styles.parentDivHome}>
              <div className={styles.leftDiv}>
                <div className={styles.imageDiv}>
                  <img
                    src={growWealth}
                    alt="Grow Wealth"
                    className={styles.image}
                    width="auto"
                    height="auto"
                  />
                </div>
              </div>

              <div className={styles.rightDiv}>
                <h2>Grow wealth, even while you sleep</h2>
                <p className={styles.mt1}>
                  Set your preferences and experience the ease of automatic
                  deposits, rebalancing, risk evaluations, dividend
                  reinvestments and more. Let your money do the hard work of
                  creating wealth for you on autopilot
                </p>
              </div>
            </div>
          {/* </div> */}

          <div className={styles.parentDiv8}>
            <div className={styles.parentDiv}>
              <div className={styles.center}>
                <h2>Invest for a secured future</h2>
                <p className={styles.mt1}>
                  Set long terms financial goals and achieve them hassle-free{" "}
                  <br />
                  with our trusted Robo-advisory system
                </p>
              </div>
            </div>
            <div className={styles.parentDiv4}>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6} xs={12}>
                  <div className={styles.flex4}>
                    <img
                      src={retirement}
                      alt="Retirement"
                      width="auto"
                      height="auto"
                    />
                    <div className={styles.flexColumn}>
                      <div className={styles.centerMobile}>
                        <h4>Retirement</h4>
                        <p className={styles.mt1}>
                          Saving for retirement is cool, but investing for it
                          earns you so much more for the quality of life you
                          deserve.
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <div className={styles.flex4}>
                    <img
                      src={capital}
                      alt="Capital"
                      width="auto"
                      height="auto"
                    />
                    <div className={styles.flexColumn}>
                      <div className={styles.centerMobile}>
                        <h4>Capital</h4>
                        <p className={styles.mt1}>
                          Want to build funds to finance a future business?
                          Automate a portfolio for high returns and get
                          accumulated earnings at maturity
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.parentDiv4}>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6} xs={12}>
                  <div className={styles.flex4}>
                    <img
                      src={education}
                      alt="Education"
                      width="auto"
                      height="auto"
                    />
                    <div className={styles.flexColumn}>
                      <div className={styles.centerMobile}>
                        <h4>Education</h4>
                        <p className={styles.mt1}>
                          Why bear the burden of your kids’ future education
                          when returns on your investments can finance that for
                          you?
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <div className={styles.flex4}>
                    <img src={others} alt="Others" width="auto" height="auto" />
                    <div className={styles.flexColumn}>
                      <div className={styles.centerMobile}>
                        <h4>Others</h4>
                        <p className={styles.mt1}>
                          Whatever your long term money goals are, we will help
                          you create investment plans for them and watch your
                          money grow.
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={styles.m50b}>
            <div className={styles.m2b}>
              <div className={styles.parentDiv2}>
                <div className={styles.leftDiv}>
                  <div className={styles.imageDiv2}>
                    <img src={phone} alt="Phone" width="auto" height="auto" />
                  </div>
                </div>

                <div className={styles.rightDiv3}>
                  <div className={styles.divflexColumn}>
                    <div className={styles.widthR}>
                      <div className={styles.stepsHeader}>
                        Automate your investment in 3 steps
                      </div>

                      <div className={styles.imageDiv2}>
                        <img
                          src={steps}
                          alt="Steps"
                          className={styles.image}
                          width="auto"
                          height="auto"
                        />
                      </div>
                    </div>
                    {/* <div className={styles.flexColumn}>
                      <div className={styles.mt4}>
                        <h3>Create an account</h3>
                        <p className={styles.mt1}>
                          Sign up for an account with your details in minutes
                        </p>
                      </div>

                      <div className={styles.mt6}>
                        <h3>Complete surveys</h3>
                        <p className={styles.mt1}>
                          Tell us about your financial situation, your
                          investment experience and attitude
                        </p>
                      </div>

                      <div>
                        <h3>We will handle the rest</h3>
                        <p className={styles.mt1}>
                          Sit back and watch your investments grow
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.parentDiv2}>
            <div className={styles.leftDiv2}>
              <div className={styles.imageDiv3}>
                <img
                  src={shareGoals}
                  alt="Share Goals"
                  className={styles.image}
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
            <div className={styles.rightDiv2}>
              <h2>
                Share your goals with us and we will take care of the rest
              </h2>
            </div>
          </div>

          {/* <div className={styles.mm2}> */}
          <div className={styles.parentDivHome}>
            <div className={styles.leftDiv}>
              <h2>You can’t work forever but your money can</h2>
              <p className={styles.mt1}>
                Have something to fall back on in the future by investing for
                the long term in quality and diverse mutual assets for such as
                bonds, real estate, stocks and cryptocurrencies.
              </p>
            </div>
            <div className={styles.rightDiv}>
              <div className={styles.flexColumn}>
                <div className={styles.mt5}>
                  <div className={styles.imageDiv2}>
                    <img
                      src={clock}
                      alt="Clock"
                      className={styles.image}
                      width="auto"
                      height="auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}

          <div className={styles.parentDiv9}>
            <div className={styles.parentDiv}>
              {/* <div className={styles.center}> */}
              <h2>Intuitive investing you can trust</h2>
              {/* </div> */}
            </div>
            <div className={styles.mb2rem}>
              <Grid container spacing={4}>
                <Grid item lg={4} md={4} xs={12}>
                  <div className={styles.investBox}>
                    <div className={styles.center}>
                      <div className={styles.imageDivHeight}>
                        <img
                          src={portfolio}
                          alt="Portfolio"
                          className={styles.image3}
                          width="auto"
                          height="auto"
                        />
                      </div>

                      <h4>Diversified Portfolio</h4>
                      <div className={styles.mt1}>
                        To reduce risks and maximize rewards, we invest your
                        money in different assets across markets
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <div className={styles.investBox}>
                    <div className={styles.center}>
                      <div className={styles.imageDivHeight}>
                        <img
                          src={report}
                          alt="Performance Report"
                          className={styles.image3}
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <h4>Performance Report</h4>
                      <div className={styles.mt1}>
                        Don’t want to monitor your investments daily? We’ll send
                        you performance reports from time to time. Nothing is
                        hidden!
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <div className={styles.investBox}>
                    <div className={styles.center}>
                      <div className={styles.imageDivHeight}>
                        <img
                          src={call}
                          alt="Call"
                          className={styles.image3}
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <h4>Speak to Support Anytime</h4>
                      <div className={styles.mt1}>
                        Contact our portfolio managers anytime you have a
                        question. We’re always at hand to assist you
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={styles.m70}>
            <div className={styles.parentDiv2}>
              <div className={styles.leftDiv}>
                <div className={styles.imageDiv2}>
                  <img
                    src={phone2}
                    alt="Phone2"
                    className={styles.image}
                    width="auto"
                    height="auto"
                  />
                </div>
              </div>
              <div className={styles.rightDiv}>
                <div className={styles.ml1}>
                  <h2>Your portfolio will be kept in check automatically</h2>
                  <p className={styles.mt1}>
                    Your portfolio will automatically adjust to best align with
                    your objectives as you invest more money or as market
                    conditions change.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mdownload}>
        <div className={styles.parentDiv5}>
          <div className={styles.parentDiv2}>
            <div className={styles.leftDiv2}>
              <h2 className={styles.widthR}>
                <span className={styles.white}>
                  Need smart investment recommendations?
                </span>
              </h2>
            </div>

            <div className={styles.rightDiv6}>
              <div className={styles.flex}>
                <div className={styles.centerMobile}>
                  <div className={styles.downloadButton3}>
                    <div className={styles.buttonText}>
                      <div>
                        <span className={styles.buttonTextInvest}>
                          Coming Soon
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invest;
